<template>
  <EmIcon v-if="hideTooltip" :icon="icon" v-bind="$attrs" />
  <EmIconTooltip
    v-else
    :icon="icon"
    :tooltip="tooltip"
    v-bind="$attrs"
  />
</template>

<script setup>
defineProps({
  hideTooltip: { type: Boolean, default: false }
})
const icon = ref('mdi-briefcase')
const tooltip = ref('This is your primary phone number')
</script>
